const appConfig = {
  "name": "account-registration",
  "version": "1.0.0",
  "stage": "develop",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-dev.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_14cFcpuS8",
        "userPoolWebClientId": "75cjajvb1b28vdjoin5jjcgves",
        "oauth": {
          "domain": "atlas-auth-dev.mit.edu",
          "scope": [
            "profile",
            "openid",
            "digital-id/user"
          ],
          "redirectSignIn": "https://account-registration-dev.mit.edu",
          "redirectSignOut": "https://account-registration-dev.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-dev.mit.edu",
        "paths": {
          "account": "account-v2",
          "digital-id": "digital-id-v1"
        }
      }
    },
    {
      "name": "us-west-1",
      "isPrimaryRegion": false,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-dev.mit.edu",
      "amplify": {
        "userPoolId": "us-west-1_zBZDEjiKC",
        "userPoolWebClientId": "5geio5v8i0osrs617a14dqiae7",
        "oauth": {
          "domain": "atlas-auth-us-west-dev.mit.edu",
          "scope": [
            "profile",
            "openid",
            "digital-id/user"
          ],
          "redirectSignIn": "https://d30x66c6voa94j.cloudfront.net",
          "redirectSignOut": "https://d30x66c6voa94j.cloudfront.net/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-us-west-dev.mit.edu",
        "paths": {
          "account": "account-v2",
          "digital-id": "digital-id-v1"
        }
      }
    }
  ]
};
export default appConfig;